<template>
  <b-tab>
    <validation-observer ref="verifyValidationStep2">
      <b-form>
        <b-card no-body>
          <div v-for="item of returnOnlySelected(paymentsData)" :key="item.employee_id" no-body class="mb-1">
            <h4 class="text-center mt-2 mb-2">
              <template v-if="item.contractor_type == 'Individual'">
                {{ item.first_name }} {{ item.last_name }}</template>
              <template v-if="item.contractor_type == 'Business'">
                {{ item.business_name }}
              </template>
            </h4>
            <b-row class="align-items-around justify-content-around m-0 p-0">
              <b-col cols="12" md="7" class="p-0 mb-1">
                <p class="m-0 p-0">Commissions: {{ item.total_commision | formatCurrency }}</p>
                <p class="m-0 p-0">Tips: {{ item.total_pay_tips | formatCurrency }}</p>
                <p class="m-0 p-0">Discounts: {{ item.employee_discount | formatCurrency }}</p>
              </b-col>
              <b-col cols="12" md="3" class="p-0 mb-1 text-center">
                <label>Total Payment for {{ item.contractor_type == 'Individual' ? item.first_name : item.business_name }}</label>
                <br>
                <h5 class="mt-1">Total: {{ sumMethod(item.total_commision, item.total_pay_tips, item.employee_discount) | formatCurrency }}</h5>
              </b-col>
              <b-col cols="12" md="11">
                <!-- employee_memo -->
                <b-form-textarea
                  id="textarea"
                  v-model="item.memo"
                  placeholder="Employee notes"
                  rows="2"
                  no-resize
                />
              </b-col>
            </b-row>
            <hr>
          </div>
          <div>
            <b-row>
              <b-col cols="12" md="12" class="text-center mb-3">
                <h3 class="mt-1">TOTAL: {{ returnTotal() | formatCurrency }}</h3>
              </b-col>
            </b-row>
          </div>
          <b-row class="align-items-around justify-content-around">
            <b-col cols="12" md="4">
              <b-button
                variant="primary"
                class="mb-2 mx-2"
                @click="$emit('changeStep', 0)"
              >
                <span class="text-nowrap">Back to select employees</span>
              </b-button>
            </b-col>
            <b-col cols="12" md="4">
              <b-button
                variant="primary"
                class="mb-2 mx-2"
                @click="validateForm"
              >
                <span class="text-nowrap">Download CSV Payments</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </b-tab>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { BButton, BCard, BCardText, BCol, BForm, BFormGroup, BFormTextarea, BRow, BTab } from 'bootstrap-vue'
  export default {
    components: {
      BTab,
      BButton,
      BCard,
      BCardText,
      BRow,
      BCol,
      BFormGroup,
      BForm,
      BFormTextarea,

      ValidationObserver,
      ValidationProvider,
    },
    emits: ['update:changeStep', 'update:validation'],
    props: {
      paymentsData: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        //
      };
    },
    methods: {
      sumMethod(a = 0, b = 0, discount = 0) {
        return (Number(a) + Number(b)) - Number(discount)
      },
      returnTotal() {
        const data = this.paymentsData
        let totalAmount = 0
        if (!data) {
          return 0
        }
        for (const item of data) {
          if (item.select_for_payment) {
            totalAmount += (Number(item.total_commision) + Number(item.total_pay_tips)) - Number(item.employee_discount)
          }
        }

        return totalAmount;
      },
      returnOnlySelected(data) {
        return data.filter((item) => item.select_for_payment)
      },
      validateForm() {
        this.$refs.verifyValidationStep2.validate().then((success) => {
          if (success) {
            this.$emit('validation', 2)
          }
        });
      },
    },
    filters: {
      formatCurrency(value) {
        const formatoMoneda = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return formatoMoneda.format(value || 0)
      },
    },
  };
</script>