import store from "@/store";
import { ref, watch } from "@vue/composition-api";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

let hasOpened = false;

export function hasValidationGusto() {
  const USER_APP_STORE_MODULE_NAME = "app-payroll";

  const toast = useToast();
  const userDataLocalStorage = JSON.parse(localStorage.getItem('userData'));

  const offices = ref([]);
  const officeFilter = ref(null);
  const readyForStart = ref(false);

  const fetchValidation = () => {
    store.dispatch(`${USER_APP_STORE_MODULE_NAME}/validateIntegration`, {
      roleId: userDataLocalStorage.roleId,
      officeId: officeFilter.value || userDataLocalStorage.dataUser.officeEmployee_officeId,
    })
      .then((data) => {
        // Getting Data Offices
        if (data.offices) {
          if (!offices.value) {
            offices.value = [];
          }
          const officeOptions = [];
          for (const item of data.offices) {
            officeOptions.push({
              label: item.name,
              value: item.id,
              id: item.id,
            });
          }

          offices.value = officeOptions;

          if (!officeFilter.value) {
            if (officeOptions.length) {
              officeFilter.value = officeOptions[0].id;
            }
          }
        }
        readyForStart.value = true;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            body: error.response.data.message,
          },
        });
      });
  };
  fetchValidation();

  return {
    officeList: offices,
    officeFilter,
    readyForStart,
  };
}
