<template>
  <b-tab>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-start">
              <b-button
                variant="primary"
                @click="selectAllMethod"
              >
                <span class="text-nowrap">{{ selectAll ? 'Select All' : 'Deselect All' }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <validation-observer ref="listPaymentsValidation">
        <b-table
          ref="refListPayments"
          class="position-relative"
          :items="paymentsData"
          responsive
          striped
          sortable
          hover
          :fields="tableColumns"
          primary-key="employee_id"
          sort-by="employee_first_name"
          show-empty
          empty-text="No matching records found"
        >
          <!-- Column: select_for_payment -->
          <template #cell(select_for_payment)="data">
            <b-form-checkbox
              v-model="data.item.select_for_payment"
              :name="'select_for_payment_' + data.item.employee_id"
              :value="true"
              :unchecked-value="false"
            >
              {{ data.item.select_for_payment ? 'Yes' : 'No' }}
            </b-form-checkbox>
          </template>

          <!-- Column: total_commision -->
          <template #cell(total_commision)="data">
            <b-form-group label="Commissions" :label-for="`totalc-${data.item.employee_id}`" :id="`totalc-group-${data.item.employee_id}`">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Commissions"
              >
                <b-form-input v-model="data.item.total_commision" min="0.00" type="number" step="0.01" :id="`totalc-${data.item.employee_id}`" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </template>

          <!-- Column: total_pay_tips -->
          <template #cell(total_pay_tips)="data">
            <b-form-group label="Tips" :label-for="`total_pay_tips-${data.item.employee_id}`">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Tips"
              >
                <b-form-input v-model="data.item.total_pay_tips" min="0.00" type="number" step="0.01" :id="`total_pay_tips-${data.item.employee_id}`" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </template>

          <!-- Column: employee_discount -->
          <template #cell(employee_discount)="data">
            <b-form-group label="Discounts" :label-for="`employee_discount-${data.item.employee_id}`">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Discounts"
              >
                <b-form-input v-model="data.item.employee_discount" min="0.00" type="number" step="0.01" :id="`employee_discount-${data.item.employee_id}`" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </template>

          <!-- Column: wage -->
          <template #cell(wage)="data">
            {{ sumMethod(data.item.total_commision, data.item.total_pay_tips, data.item.employee_discount) | formatCurrency }}
          </template>

          <!-- Column: contractor_type -->
          <template #cell(contractor_type)="data">
            <div>
              <b-badge v-if="data.item.contractor_type == 'Must Sync With Gusto'" variant="warning">
                {{ data.item.contractor_type }}
              </b-badge>
              <b-badge v-else variant="primary">
                {{ data.item.contractor_type }}
              </b-badge>
            </div>
          </template>
        </b-table>
      </validation-observer>
      <div class="mx-2 mb-2">
        <p class="text-center m-0 p-0">
          <span class="text-muted">Showing {{ paymentsData.length }} entries </span>
        </p>
        <p class="text-center m-0 p-0">
          <span class="text-primary">Payment Total: {{ returnTotal() | formatCurrency }} </span>
        </p>
      </div>
      <b-button
        variant="primary"
        class="mb-2 mx-2"
        @click="validateForm"
      >
        <span class="text-nowrap">Preview Payments</span>
      </b-button>
    </b-card>
  </b-tab>
</template>
<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import { BCard, BTable, BRow, BCol, BFormCheckbox, BButton, BFormInput, BTab, BFormGroup, BBadge } from 'bootstrap-vue'

  import { createToastInterface } from "vue-toastification";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  const toast = createToastInterface();
  export default {
    components: {
      BCard,
      BTable,
      BRow,
      BCol,
      BFormCheckbox,
      BButton,
      BFormInput,
      BFormGroup,
      BTab,
      BBadge,

      ValidationObserver,
      ValidationProvider,
    },
    emits: ['update:changeStep'],
    props: {
      paymentsData: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            key: 'select_for_payment',
            label: 'Select For Payment',
            sortable: true,
          },
          {
            key: 'first_name',
            label: 'First Name',
            sortable: true,
          },
          {
            key: 'last_name',
            label: 'Last Name',
            sortable: true,
          },
          {
            key: 'business_name',
            label: 'Business Name',
            sortable: true,
          },
          {
            key: 'total_commision',
            label: 'Commissions',
            sortable: true,
          },
          {
            key: 'total_pay_tips',
            label: 'Tips',
            sortable: true,
          },
          {
            key: 'employee_discount',
            label: 'Discounts',
            sortable: true,
          },
          {
            key: 'wage',
            label: 'Total Pay',
            sortable: true,
          },
          {
            key: 'contractor_type',
            label: 'Contractor Type',
            sortable: true,
          },
        ],
        selectAll: true,
      }
    },
    methods: {
      selectAllMethod() {
        this.selectAll = !this.selectAll
        if (this.paymentsData && this.paymentsData.length > 0) {
          for (const item of this.paymentsData) {
            item.select_for_payment = this.selectAll
          }
        }
      },
      sumMethod(a = 0, b = 0, discount = 0) {
        return (Number(a) + Number(b)) - Number(discount)
      },
      returnTotal() {
        const data = this.paymentsData
        let totalAmount = 0
        if (!data) {
          return 0
        }
        for (const item of data) {
          if (item.select_for_payment) {
            totalAmount += (Number(item.total_commision) + Number(item.total_pay_tips)) - Number(item.employee_discount)
          }
        }

        return totalAmount;
      },
      validateForm() {
        if (!this.paymentsData || this.paymentsData.length === 0) {
          return
        }
        const validateChecks = this.paymentsData.filter((item) => item.select_for_payment)
        if (validateChecks.length === 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: "Please select at least one employee to pay",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
          return
        }
        const validateTotal = this.returnTotal()
        if (validateTotal <= 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: "The total payment must be greater than 0.00",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
          return
        }
        this.$refs.listPaymentsValidation.validate().then((success) => {
          if (success) {
            this.$emit('changeStep', 1)
          }
        });
      },
    },
    filters: {
      formatCurrency(value) {
        const formatoMoneda = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return formatoMoneda.format(value || 0)
      },
    },
  }
</script>