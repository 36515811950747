import store from "@/store";

// Notification
import { createToastInterface } from "vue-toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const toast = createToastInterface();

export function workerPayments () {
  const USER_APP_STORE_MODULE_NAME = "app-payroll";

  const fetchData = (office, queryParams) => {
    return store.dispatch(`${USER_APP_STORE_MODULE_NAME}/fetchManualPayroll`, { office: office.value || office, queryParams })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Failed to fetch worker configuration",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
  };

  const sendPayments = (payload) => {
    const data = {
      items: [],
    }
    for (let index = 0; index < payload.length; index++) {
      const item = payload[index];
      if (item.select_for_payment) {
        const payment = {
          contractor_type: item.contractor_type,
          first_name: item.first_name,
          last_name: item.last_name,
          ssn: item.ssn,
          business_name: item.business_name,
          ein: item.ein,
          memo: item.memo,
          hours_worked: item.hours_worked,
          wage: item.wage,
          reimbursement: item.reimbursement,
          bonus: item.bonus,
          invoice_number: item.invoice_number,
        }
  
        let totalWage = (Number(item.total_commision) + Number(item.total_pay_tips)) - Number(item.employee_discount)
        payment.wage = totalWage.toFixed(2)
        data.items.push(payment);
      }
    }
    return store.dispatch(`${USER_APP_STORE_MODULE_NAME}/getCsvFromPayments`, data)
      .then((data) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Payments Successfully Sent',
            icon: "AlertCircleIcon",
            variant: "success",
          },
        });
        return data;
      })
      .catch((error) => {
        let errorMsg = "Failed to send payments";
        if (error.response) {
          if (error.response.data.error) {
            errorMsg = error.response.data.error;
          }
        }
        toast({
          component: ToastificationContent,
          props: {
            title: errorMsg,
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        throw error;
      });
  }

  return {
    fetchData,
    sendPayments,
  }
}