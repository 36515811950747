import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    validateIntegration(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/payroll/validate-integration", payload)
          .then((response) => {
            resolve(response.data.response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchManualPayroll(ctx, payload = { office: 0, queryParams: {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/payroll/calculate/manual/office/${payload.office}`, { params: payload.queryParams })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    getCsvFromPayments(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/payroll/create-payment-csv`, payload, {
            responseType: "blob",
          })
          .then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'gusto-payment.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    uploadCsvSync(ctx, data = { officeId: 0, data: null }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/employees/contractors-gusto/sync-preview/${data.officeId}`, data.data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    confirmSync(ctx, data = { officeId: 0, data: null }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/employees/contractors-gusto/sync/${data.officeId}`, data.data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    }
  },
};
