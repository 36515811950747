<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Office</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="officeFilter"
            :options="officeOptions"
            :disabled="disabled > 0 ? true : false"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:officeFilter', val)"
          />
        </b-col>
        <b-col v-if="integration" cols="12" md="4" class="mb-md-0 mb-2">
          <div class="d-flex align-items-around">
            <div>
              <label>Range Date Payment</label>
              <div class="d-flex align-items-center">
                <feather-icon icon="CalendarIcon" size="20" />
                <flat-pickr
                  v-model="rangePickerData"
                  :config="{ mode: 'range', maxDate: new Date() }"
                  class="form-control flat-picker bg-transparent border-0 shadow-none"
                  placeholder="YYYY-MM-DD"
                  @input="(val) => $emit('updateRangePicker', val)"
                  :disabled="disabled > 0 ? true : false"
                />
              </div>
            </div>
            <div>
              <br>
              <b-button
                variant="primary"
                @click="reset"
                :disabled="disabled > 0 ? true : false"
                >
                  <feather-icon icon="RotateCcwIcon" size="20" />
                  Last week
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
  
<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
    flatPickr,
  },
  props: {
    officeFilter: {
      type: [String, Number, null],
      default: null,
    },
    officeOptions: {
      type: Array,
      required: true,
    },
    integration: {
      type: [Boolean, String, null],
      default: null,
    },
    disabled: {
      type: Number,
      default: 0,
    },
  },
  emits: ['updateRangePicker', 'updateDatePayments'],
  created() {
    // this.reset()
  },
  data() {
    return {
      rangePickerData: null,
      validateSelectedDate: null,
    }
  },
  methods: {
    reset() {
      if (this.disabled > 0) return
      let startDate;
      let endDate;
      const dateNow = new Date()

      const firstDay = new Date(dateNow.setDate(dateNow.getDate() - dateNow.getDay() - 7))
      startDate = firstDay.toISOString().split('T')[0]

      const lastDay = new Date(dateNow.setDate(dateNow.getDate() - dateNow.getDay() + 6))
      endDate = lastDay.toISOString().split('T')[0]

      this.rangePickerData = `${startDate} to ${endDate}`
      this.$emit('updateRangePicker', this.rangePickerData)
    }
  }
};
</script>
  
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
