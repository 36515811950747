<template>
  <div>
    <!-- Filters -->
    <PaymentsFilters
      :officeFilter.sync="officeFilter"
      :officeOptions="officeList"
      :integration.sync="readyForStart"
      @updateRangePicker="updateRangePicker"
      :disabled.sync="step"
    />

    <div v-if="paymentsData">
      <b-tabs v-model="step">
        <TabPaymentsList @changeStep="changeStep" :paymentsData.sync="paymentsData" />
        <TabVerifyPayment @changeStep="changeStep" @validation="distpachFinish" :paymentsData.sync="paymentsData" />
      </b-tabs>
    </div>
  </div>
</template>
<script>
  import store from "@/store";
  import router from "@/router";
  import { ref, onUnmounted, watch } from "@vue/composition-api";

  // Methods
  import { hasValidationGusto } from './../validationGusto';
  import payrollStoreModule from '../payrollStoreModule';
  import { workerPayments } from './workerPayment'

  // Components
  import PaymentsFilters from './PaymentsFilter.vue'
  import TabPaymentsList from './TabPaymentList.vue'
  import TabVerifyPayment from './TabVerifyPayment.vue'
  import {
    BCard,
    BTable,
    BTabs,
  } from "bootstrap-vue";

  export default {
    components: {
      PaymentsFilters,
      TabPaymentsList,
      TabVerifyPayment,
      BCard,
      BTable,
      BTabs,
    },
    setup() {
      const USER_APP_STORE_MODULE_NAME = "app-payroll";

      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.registerModule(USER_APP_STORE_MODULE_NAME, payrollStoreModule);
      }

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        }
      });

      const { officeList, officeFilter, readyForStart } = hasValidationGusto()
      const rangePicker = ref('')
      const paymentsData = ref([])

      watch([readyForStart, rangePicker, officeFilter], () => {
        if (readyForStart.value && rangePicker.value) {
          const query = {
            startDate: rangePicker.value[0],
            endDate: rangePicker.value[1],
          }
          workerPayments().fetchData(officeFilter.value, query)
            .then((data) => {
              paymentsData.value = data
            })
        } else {
          paymentsData.value = null
        }
      })
      
      return {
        officeList: officeList,
        officeFilter: officeFilter,
        readyForStart: readyForStart,
        rangePicker: rangePicker,
        paymentsData: paymentsData,
      }
    },
    data() {
      return {
        step: 0,
      }
    },
    methods: {
      updateRangePicker(value) {
        if (value) {
          if (value.split(' to ').length > 1) {
            this.rangePicker = value.split(' to ')
          }
        }
      },
      distpachFinish() {
        workerPayments().sendPayments(this.paymentsData)
          .then(() => {
            this.changeStep(0)
          })
      },
      changeStep(step) {
        this.step = step || 0
      },
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  ul.nav.nav-tabs {
    display: none !important;
  }
</style>